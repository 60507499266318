<template>
    <div>
        <div v-if="!loading">
            <!--SIDE BAR-->
            <side-bar v-if="step < 4">
                <template slot="links">
                    <people-document-sign-left-side-bar-component :itemData.sync="item_data" />
                </template>
            </side-bar>

            <!-- MAIN CONTENT -->
            <div class="main-content">

                <div @click="$sidebar.displaySidebar(false)">
                    <fade-transition :duration="200" origin="center top" mode="out-in">

                        <b-row> 
                            <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                                <div class="mt-3 mb-3 d-flex align-items-center" v-if="step > 0">
                                    <b-link v-if="step > 1 && step < 3" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="chevron-left" font-scale="1" class="mr-2"></b-icon>{{trans('previous-step',253)}}</b-link>
                                </div>

                                <ValidationObserver ref="form">
                                    <form @submit.prevent="onSubmit">
                                        <content-step-1 :itemData.sync="item_data" v-if="step === 1"/>
                                        <content-step-2 :itemData.sync="item_data" :saveSignature.sync="save_signature" :showEmptySignaturePadAlert="show_empty_signature_pad_alert" @saveSignaturedDocument="saveSignaturedDocument" v-if="step === 2"/>
                                        <content-step-3 :itemData.sync="item_data" v-else-if="step === 3" />
                                        <content-step-4 :itemData.sync="item_data" v-else-if="step === 4"/>
                                    </form>
                                </ValidationObserver>

                                <div v-if="step < 3" class="mt-4 mb-3" >
                                    <app-button @click="nextStep()" :disabled="loading">{{next_step_label}}</app-button>
                                    <app-button class="app-local-btn-delete" @click="cancelDocument()" v-if="step === 1" :disabled="loading == true" :loading="loading">{{$translate(`${this.item_data.type}.cancel_document`)}}</app-button>
                                </div>

                            </b-col>
                        </b-row>

                    </fade-transition>
                </div>
            </div>
        </div>

        <!-- LOADER HAPPY NURSE -->
        <b-row v-if="loading">
            <app-loader />
        </b-row>

        <people-document-sign-cancel-document v-if="mCancelDocument.show" :show.sync="mCancelDocument.show" :args.sync="mCancelDocument.args" :result.sync="mCancelDocument.result" :return.sync="mCancelDocument.return" />

    </div>
</template>
<script>
//KKY
import { FadeTransition } from 'vue2-transitions';
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppLoader from '@core/components/AppLoader.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import PeopleDocumentSignLeftSideBarComponent from './PeopleDocumentSignLeftSideBarComponent.vue';
import ContentStep1 from './Components/PeopleDocumentSignStep1Component.vue';
import ContentStep2 from './Components/PeopleDocumentSignStep2Component.vue';
import ContentStep3 from './Components/PeopleDocumentSignStep3Component.vue';
import ContentStep4 from './Components/PeopleDocumentSignStep4Component.vue';
import PeopleDocumentSignCancelDocument from './Components/PeopleDocumentSignCancelDocument.vue';
export default {
    components: {
        ValidationObserver,
        FadeTransition,
        AppLoader,
        PeopleDocumentSignLeftSideBarComponent,
        ContentStep1,
        ContentStep2,
        ContentStep3,
        ContentStep4,
        PeopleDocumentSignCancelDocument
    },

    created(){
        this.getData();
    },

    watch: {

        "mCancelDocument.result": {
            handler: function(val){
                if(val == 1){
                    this.step = 4;
                    this.item_data.step = 4;
                }
            },
            deep: true
        }
        
    },

    computed: {

        classes() {
            let color = `bg-${this.type}`;
            let classes = [
                { 'navbar-transparent': this.transparent },
                { [`navbar-expand-${this.expand}`]: this.expand }
            ];
            if (this.position) {
                classes.push(`navbar-${this.position}`);
            }
            if (!this.transparent) {
                classes.push(color);
            }
            return classes;
        },
        hasMenu() {
            return this.$slots.default;
        }
       
    },
    
    data() {
        return {
            step: 1,

            item_data: {
                step: 1,
                base_64: null,
                signed_document: null,
                is_empty: true,
                signature: null,
                type: null,
                office_name:null,
                email: null,
                phone: null
            },

            loading: false,
            show_success_alert: false,
            show_unsuccess_alert: false,
            next_step_label: "",
            save_signature: false,
            app_alerts: new AppAlerts(),
            show_empty_signature_pad_alert: false,

            show: false, 
            transparent: false,
            expand: 'md',
            menuClasses: '',
            containerClasses: 'container',
            type: '',
            mCancelDocument: {show: false,  args: {
                type: null,
                token: this.$route.params.token
            }, result: 0, return: null }
        };
    },
    
    
    methods: {
       getData(){

           this.loading = true;

           axios
                .get("people/document-sign/getData/" + this.$route.params.token)
                .then((res) => {  
                    this.item_data.base_64 = res.data.doc_base64;
                    this.item_data.type = res.data.type;
                    this.step = 1;                 

                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                   this.loading = false;
                })
                .finally(() => {
                    this.next_step_label = this.$translate(`${this.item_data.type}.sign_document`);
                    this.loading = false;
                });  
       },

       goBack(){
            this.step = 1;
            this.item_data.step = 1;
            this.loading = false;
            this.next_step_label = this.$translate(`${this.item_data.type}.sign_document`);
       },

       nextStep(){
           if(this.step == 1){
                
                this.loading = true;
                this.step = 2;
                this.item_data.step = 2;
                this.next_step_label = this.$translate(`${this.item_data.type}.send_document`);
                setTimeout(() => {
                    this.loading = false;
                }, 1000);

            }else if(this.step == 2){
                
                this.save_signature = !this.save_signature;
                
            }
       },

       saveSignaturedDocument: function() {
           
            if(this.item_data.is_empty == false){

                this.loading = true;
                this.sendSignature();

            }else{
                this.show_empty_signature_pad_alert = !this.show_empty_signature_pad_alert;
            }
        },

        sendSignature: function(){

            var postData = {
                signature: this.item_data.signature
            }
            
            
            /*setTimeout(() => {
                    this.loading = false;
                    this.step = 3;
                    this.item_data.step = 3;
                }, 2000);*/

            axios
                .post("people/document-sign/sign/" + this.$route.params.token, postData)
                .then((res) => {
                    
                    this.step = 3;
                    this.item_data.step = 3;
                    this.item_data.office_name = res.data.office_name;
                    this.item_data.phone = res.data.phone;
                    this.item_data.email = res.data.email;
                    
                })
                .catch((error) => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                    
                });

        },

       cancelDocument(){
           this.mCancelDocument.args.type = this.item_data.type;
           this.mCancelDocument.show = true;
            // this.app_alerts.openAlertConfirmation(this.$translate(`${this.item_data.type}.cancel_sign_confirmation`)).then(res => { 
            //     if(res == true){

            //         this.loading = true;

            //         axios
            //             .post("people/document-sign/cancelDocument/" + this.$route.params.token, null)
            //             .then((res) => {
            //                 this.step = 4;
            //                 this.item_data.step = 4;
            //             })
            //             .catch((error) => {
            //                 //console.log(error);
            //                 this.loading = false;
            //                 this.step = 4;
            //             })
            //             .finally(() => {
            //                 this.loading = false;
            //                 this.step = 4;
                            
            //             });
            //     }
            // });
       },

       toggleMenu() {
      this.$emit('change', !this.show);
    },
    closeMenu() {
      this.$emit('change', false);
    }

    },

};
</script>

<style scoped>
    .app-local-btn-delete{
        background-color: #F04438;
        border-color: #F04438;
    }
</style>
