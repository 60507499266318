<template>
    <div>
        <b-container class="mt-8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="10" md="6">
                    <div class="w-100 text-center">

                        <div class="d-flex justify-content-center">
                            <img src="@/assets/images/form_done.png" width="400 px"/>
                        </div>

                        <h2 class="mb-1">{{trans('fw-form-sent',248)}}</h2>
                        <p class="mb-2">{{trans('fw-form-sent-message-second',248,{office_name: itemData.office_name, phone: itemData.phone, email: itemData.email})}}</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>   
    </div>
</template>

<script>

export default {
    components: {
    },

    props: ["itemData"],

    created(){

    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },
    
    watch:{},

    data(){
        return{
        }
    },
    
    methods:{}
};
</script>

<style></style>
<style lang="scss" scope>
</style>
